import React from 'react';
import PortfolioItem from './PortfolioItem';

const Img1 = ({data,i}) => {
  const img = data?.col0[0]?.featuredImage?.node;
  const text = data?.col0[0]?.acfPost?.subtitle;
  const type = data?.col0[0]?.acfPost?.type;
  const gallery = data?.col0[0]?.acfPost?.gallery;
  const video = data?.col0[0]?.acfPost?.video;

  return (
    <section data-scroll-section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <PortfolioItem i={i + '-1'} img={img} text={text} type={type} gallery={gallery} video={video} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Img1;
