import HoverItem from '../hover/hover-item';

export default class Hover {
  constructor(el) {
      // el is the menu element (<nav>)
      this.DOM = {el: el};
      // the menu item elements (<a>)
      this.DOM.hoverItems = this.DOM.el.querySelectorAll('.row');
      // menu item properties that will animate as we move the mouse around the menu
      // we will be using interpolation to achieve smooth animations. 
      // the “previous” and “current” values are the values to interpolate. 
      // the value applied to the element, this case the image element (this.DOM.reveal) will be a value between these two values at a specific increment. 
      // the amt is the amount to interpolate.
      this.animatableProperties = {
          // translationX
          tx: {previous: 0, current: 0, amt: 0.08},
          // translationY
          ty: {previous: 0, current: 0, amt: 0.08},
          // Rotation angle
          rotation: {previous: 0, current: 0, amt: 0.08},
          // CSS filter (brightness) value
          brightness: {previous: 1, current: 1, amt: 0.08}
      };
      // array of MenuItem instances
      this.hoverItems = [];
      // initialize the MenuItems
      [...this.DOM.hoverItems].forEach((item, pos) => this.hoverItems.push(new HoverItem(item, pos, this.animatableProperties)));
      // show the menu items (initial animation where each menu item gets revealed)
  }
}


