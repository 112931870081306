import React from 'react';

const Brand = () => {
  return (
    <div className="container-fluid pt-10 pb-100">
      <div className="row pb-100" data-scroll>
        <div className="col-12 h1-big anim">
           Design. Brand. Technology.
        </div>
      </div>
    </div>
  )
}

export default Brand
