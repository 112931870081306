import React from 'react';
import PortfolioItem from './PortfolioItem';

const Img2 = ({data, i}) => {
  const img0 = data?.col0 ? data?.col0[0]?.featuredImage?.node : null;
  const text0 = data?.col0 ? data?.col0[0]?.acfPost?.subtitle : null;
  const type0 = data?.col0 ? data?.col0[0]?.acfPost?.type : null;
  const gallery0 = data?.col0 ? data?.col0[0]?.acfPost?.gallery : null;
  const video0 = data?.col0 ? data?.col0[0]?.acfPost?.video : null;

  const img1 = data?.col1 ? data?.col1[0]?.featuredImage?.node : null;
  const text1 = data?.col1 ? data?.col1[0]?.acfPost?.subtitle : null;
  const type1 = data?.col1 ? data?.col1[0]?.acfPost?.type : null;
  const gallery1 = data?.col1 ? data?.col1[0]?.acfPost?.gallery : null;
  const video1 = data?.col1 ? data?.col1[0]?.acfPost?.video : null;


  return (
    <section data-scroll-section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <PortfolioItem i={i + '-2'} img={img0} text={text0} type={type0} gallery={gallery0} video={video0} />
          </div>

          <div className="col-lg-6">
            <PortfolioItem i={i + '-3'} img={img1} text={text1} type={type1} gallery={gallery1} video={video1} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Img2;
