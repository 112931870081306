import React from 'react';

const IntroCode = (data) => {
  const intro = data?.data;

  return (
    <section data-scroll-section>
      <div className="container-fluid pt pb-100">
        <div className="row">
          <div className="col-lg-9" data-scroll>
            {intro.text && (
              <h1 className="anim" dangerouslySetInnerHTML={{__html: intro.text}}></h1>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default IntroCode
