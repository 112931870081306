import React from 'react';
import { useACFOptionsQuery } from "../../components/hooks/useACFOptionsQuery"

const Intro = (data) => {
  const intro = data?.data;
  const {
    allWpPost: { totalCount: total },
    wp: {
      footer: { themeOptions: options }
    },
  } = useACFOptionsQuery()

  return (
    <section data-scroll-section>
      <div className="container-fluid pt">
        <div className="row" data-scroll>
          <div className="col-lg-6">
            <div className="anim h1-extra">{total}</div>
            <div className="mt-10 mb-8 anim" style={{ '--delay': .3 }}><span className="text-gray">samples of our work</span></div>
          </div>
          <div className="col-lg-6 anim" style={{ '--delay': .2 }}>
            <div className="anim">
              {intro.text && (
                <h1 className="h1-small-offset" dangerouslySetInnerHTML={{__html: intro.text}}></h1>
              )}
            </div>
            <div className="mt-10 mb-8 hover-rm-underline fs-14 anim" style={{ '--delay': .3 }}><a target="_blank" rel="noreferrer" href={options.instagram}>instagram</a></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Intro
