import React from 'react';
import Brand from '../footer/brand';
import { useACFOptionsQuery } from "../../components/hooks/useACFOptionsQuery"

const Footer = (params) => {
  const isContact = params.isContact ? params.isContact : false;
  const y = new Date().getFullYear();
  const {
    wp: {
      footer: { themeOptions: data }
    },
  } = useACFOptionsQuery()

  const address = data.address + '<br><span class="d-block text-gray">© ' + y + ' Made. All Rights Reserved. </span>';

  return (
    <footer data-scroll-section className={isContact ? "contact" : ""}>
      <div className="pt-100"></div>
      <Brand />
      <div className="container-fluid pb-lg-4 pb-10" data-scroll>
        <div className="row pb-100 mb-10 lh-1">
          <div className="col-1 text-nowrap fs-14 more-prev anim mb-2 mb-lg-0" style={{ '--delay': .1 }}><span>{data.footerText}</span></div>
          <div className="offset-6 offset-lg-5 col-4 fs-14 hover-rm-underline more lh-1 anim" style={{ '--delay': .3 }}>
            <a href={'mailto:' + data.email}>{data.email}</a><br/>
            <a target="_blank" rel="noreferrer" className="d-inline-block mt-1" href={data.instagram}>instagram</a>
            <a target="_blank" rel="noreferrer" href="https://hellheaven.co/">hellheaven.co</a>
          </div>
        </div>
        <div className="row">
          {data.address && (
            <div className="col-lg-6 anim" style={{ '--delay': .2 }} dangerouslySetInnerHTML={{__html: address}}></div>
          )}

          {data.stgu && (
            <div className="col-lg-6 flex-column mt-10 pt-10 pt-lg-0 mt-lg-0 flex-lg-row d-flex justify-content-between align-items-lg-end anim" style={{ '--delay': .25 }}>
              <div className="mb-10 mb-lg-0" dangerouslySetInnerHTML={{__html: data.stgu}}></div>
              <div className="easy fw-medium mt-10 mt-lg-0">easy<br/>is<br/>boring</div>
            </div>
          )}
        </div>
      </div>
    </footer>
  )
}

export default Footer
