import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const AboutCode = (data) => {
  const about = data?.data;

  return (
    <section data-scroll-section>
      <div className="container-fluid pb-100">
        <div className="row pt-lg-10 mt-lg-10">
          <div className="col-lg-3 paragraph mb-10 mb-lg-0" data-scroll data-scroll-speed="1">
            {about.textImg && (
              <div className="anim" dangerouslySetInnerHTML={{__html: about.textImg}} style={{ '--delay': .2 }}>
              </div>
            )}
          </div>
          {about.img && (
          <div className="col-lg-6 ms-auto anim-img paragraph" data-scroll data-scroll-speed="-.5">
              <GatsbyImage image={getImage(about.img.localFile)} alt={about.img.altText} as="span" />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default AboutCode
