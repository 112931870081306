import React from 'react';

const ColumnsCode = (data) => {
  const intro = data?.data;

  return (
    <section data-scroll-section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 offset-lg-6 pt-7" data-scroll>
            {intro.columnsTitle && (
              <h2 className="pb-100 anim" dangerouslySetInnerHTML={{__html: intro.columnsTitle}}></h2>
            )}
          </div>

          <div className="col-lg-3 offset-lg-6 paragraph mb-10 pb-8" data-scroll>
            {intro.column0 && (
              <div className="anim" style={{ '--delay': .1 }} dangerouslySetInnerHTML={{__html: intro.column0}}>
              </div>
            )}
          </div>
          <div className="col-lg-3 paragraph mb-10 pb-8" data-scroll>
            {intro.column1 && (
              <div className="anim" style={{ '--delay': .3 }} dangerouslySetInnerHTML={{__html: intro.column1}}>
              </div>
            )}
          </div>
        </div>
        <div className="row pt-100 pb-10" data-scroll>
          <div className="col-lg-1 text-nowrap fs-14 more-prev anim" style={{ '--delay': .1 }}><span>have an idea in mind?</span></div>
          <div className="offset-lg-5 col-lg-4 fs-14 hover-rm-underline more lh-1 anim" style={{ '--delay': .3 }}>
            <span className="d-inline-block mb-1">drop us a line</span><br/>
            <a href="mailto:code@madebymade.pl">code@madebymade.pl</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ColumnsCode
