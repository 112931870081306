// import React from 'react';
// const Portfolio = () => {
//   return (
//     <></>
//   )
// }

// export default Portfolio

import React from 'react'
import Img1 from './img1';
import Img2 from './img2';
import Img3 from './img3';

const Portfolio = ({ sections, secID }) => {
  const sectionsComponents = {
    Page_Acfportfolio_Sections_Row0: Img1,
    Page_Acfportfolio_Sections_Row2: Img2,
    Page_Acfportfolio_Sections_Row1: Img3
  }

  let sectionsContent = '';

  if (sections) {
    sectionsContent = sections.map((section, key) => {
      const Section = sectionsComponents[section.fieldGroupName]
      const kkey = secID + '_' + key;
      if (Section) {
        return <Section i={key} key={kkey} index={kkey} data={section} />
      }
      return <div index={kkey}>{section.type}</div>
    })
  }
  
  
  return (
    <>
      {sectionsContent}
    </>
  )
}


export default Portfolio;