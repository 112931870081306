import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation } from 'swiper/modules';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getRatio } from '../../helpers/helpers.js';

const PortfolioItem = (data) => {
  const id = data?.i ? data?.i : 0;
  const img = data?.img;
  const gallery = data?.gallery;
  const video = data?.video;
  const text = data?.text;
  const type = data?.type;
  let isGallery = gallery ? gallery?.length : false;

  return (
    <>
      {(img || gallery) && type === 'gallery' && isGallery && (
        <div className="portfolio-item portfolio-item-hover portfolio-item-full anim-img" data-scroll>
          <Swiper 
            modules={[Navigation, EffectFade]} 
            effect="fade"
            loop={1}
            spaceBetween={0}
            slidesPerView={1}
            navigation={{
              nextEl: '.swiper-next' + id
            }}
          >
          {img && (
            <SwiperSlide>
              <button className={"overflow-hidden d-block p-0 w-100 swiper-next" + id} aria-label="Next slide">
                <GatsbyImage image={getImage(img.localFile)} alt={img.altText} as="span" style={{ '--delay': .1 }} />
              </button>
            </SwiperSlide>
          )}
          {gallery && gallery.map((g) => (
            <SwiperSlide key={g.databaseId}>
              <button className={"overflow-hidden d-block p-0 w-100 swiper-next" + id} aria-label="Next slide">
                <GatsbyImage image={getImage(g.localFile)} alt={g.altText} as="span"/>
              </button>
            </SwiperSlide>
          ))}
          </Swiper>
          {text && (
            <span className="txt fs-18 my-3" dangerouslySetInnerHTML={{__html: text}}></span>
          )}
        </div>
      )}


      {!isGallery && img && (
        <div className="portfolio-item portfolio-item-full anim-img" data-scroll>
           <GatsbyImage image={getImage(img.localFile)} alt={img.altText} as="span" style={{ '--delay': .1 }} />
          {text && (
            <span className="txt fs-18 my-3" dangerouslySetInnerHTML={{__html: text}}></span>
          )}
        </div>
      )}

      {video && type === 'video' && (
        <div className="portfolio-item portfolio-item-full anim-img" data-scroll>
          <button aria-label="Play/Pause video" className="ratio overflow-hidden p-0" style={{'--bs-aspect-ratio': getRatio(video?.mediaDetails?.width, video?.mediaDetails?.height)}}>
            <video playsInline autoPlay muted loop onClick={e => e.target.paused ? e.target.play() : e.target.pause()}>
              <source src={ video?.mediaItemUrl } type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </button>
          {text && (
            <span className="txt fs-18 my-3" dangerouslySetInnerHTML={{__html: text}}></span>
          )}
        </div>
      )}
    </>
  )
}

export default PortfolioItem
