import React from 'react';

const Table = (data) => {
  const table = data?.data;
  const projects = table.projects

  return (
    <section data-scroll-section>
      {projects && (
        <div className="container-fluid pt pb-100 table">

          <div className="row th" data-scroll>
            <div className="col-12 anim">
              <span className="text-gray">Some of our coding realisations</span>
            </div>
          </div>

          {projects.map((row, i) => {
            let url = row?.url.replace('https://', '').replace('http://', '');
            url = url.slice(0, url.lastIndexOf('/'))

            return (
              <div className="hover" key={i} >
                <a 
                  href={row?.url} 
                  rel="noreferrer" 
                  target="_blank" 
                  className="row" 
                  style={{ '--delay': i/5 }} 
                  data-scroll
                >
                  <span className="hover-reveal d-none d-md-block"><span className="hover-reveal__inner"><span className="hover-reveal__img" style={{ 'backgroundImage': "url(" + row?.img?.publicUrl + ")" }}></span></span></span>
                  <span className="col-md-6 anim pb-1 pb-md-0">
                    {row?.title}
                  </span>
                  <span className="col-md-4 fs-12 anim pb-1 pb-md-0">
                    <span className="text-gray">{row?.desc}</span>
                  </span>
                  <span className="col-md-2 fs-12 anim">
                    {url}
                  </span>
                </a>
            </div>
          )
          })}
        </div>
      )}
    </section>
  )
}

export default Table
