import React, { useEffect } from 'react';
import { fade } from '../helpers/helpers'
import { graphql } from "gatsby"
import { useLocomotiveScroll } from 'react-locomotive-scroll'
import Hover from '../components/code/hover/hover';

import Intro from '../components/portfolio/intro';
import Portfolio from '../components/portfolio/portfolio';
import IntroCode from '../components/code/intro';
import AboutCode from '../components/code/about';
import ColumnsCode from '../components/code/columns';
import Table from '../components/code/table';
import Footer from '../components/footer';

import logo from '../images/static/logo-800.gif';

// markup
const Page = ({data, transitionStatus}) => {
  const { scroll } = useLocomotiveScroll()
  const page = data?.wpPage;
  const template = page?.template.templateName;
  const sections = page?.acfPortfolio?.sections;

  useEffect(() => {
    fade('main');
      const hoverLink = document.querySelectorAll('.table .hover');
      hoverLink.forEach(row => {new Hover(row)});
  }, []);

  useEffect(() => {
    if (scroll && transitionStatus === 'entering') {
      scroll.scrollTo(0, { duration: 0, easing: 0, disableLerp: true });
      scroll.update();
    }
  }, [transitionStatus]);

  return (
    <>
    {template === 'Portfolio' && (
      <>
        <Intro data={page?.acfPortfolio} />
        <Portfolio sections={sections} secID={page.id} />
      </>
      )}

      {template === 'Xcode' && (
        <>
          <IntroCode data={page?.acfCode} />
          <AboutCode data={page?.acfCode} />
          <ColumnsCode data={page?.acfCode} />
          <Table data={page?.acfCode} />
        </>
        )}
      <Footer/>
    </>
    )
}

export default Page



export const query = graphql`


fragment postImg on WpPost {
  id
  featuredImage {
    node {
      id
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)      
        }    
      }
    }
  }
  acfPost {
    subtitle
    type
    video {
      mediaItemUrl
      mediaDetails {
        width
        height
      }
    }
    gallery {
      altText
      databaseId
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)      
        }    
      }
    }
  }
}

query($id: String!) {
  wpPage(id: { eq: $id }) {
    id
    status
    title
    template {
      templateName
    },
    acfCode {
      text
      textImg
      img {
        id
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)      
          }    
        }
      }
      columnsTitle
      column0
      column1
      projects {
        desc
        title
        url
        img {
          id
          publicUrl
        }
      }
    }
    acfPortfolio {
      text
      sections {
        ... on WpPage_Acfportfolio_Sections_Row0 {
          fieldGroupName
          col0 {
            ...postImg
          }
        }
        ... on WpPage_Acfportfolio_Sections_Row2 {
          fieldGroupName
          col0 {
            ...postImg
          }
          col1 {
            ...postImg
          }
        }
        ... on WpPage_Acfportfolio_Sections_Row1 {
          fieldGroupName
          reverse
          col0 {
            ...postImg
          }
          col1 {
            ...postImg
          }
          col2 {
            ...postImg
          }
        }
      }
    }
  }
}
`


export function Head({ location, params, data, pageContext }) {
  const page = data?.wpPage;
  const url = typeof window !== `undefined` ? window.location.href : 'https://madebymade.pl' + location;
  const template = page?.template.templateName;
  return (
    <>
      <title>{data.wpPage.title} - MADE - Design. Brand. Technology.</title>
      <meta name="description" content={template === 'Portfolio' ? page?.acfPortfolio?.text : "We'd love to hear from you. hello@madebymade.pl" } />
      <meta name="og:description" content={template === 'Portfolio' ? page?.acfCode?.text : "We'd love to hear from you. hello@madebymade.pl" } />
      <meta name="og:image" content={logo} />
      <meta property="og:type" content="website"/>
      <meta property="og:url" content={url} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="madebymade.pl" />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={data.wpPage.title + ' - MADE'} />
      <meta name="twitter:description" content={template === 'Portfolio' ? page?.acfCode?.text : "We'd love to hear from you. hello@madebymade.pl" } />
      <meta name="twitter:image" content={logo} />
    </>
  )
}