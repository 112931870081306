import React from 'react';
import PortfolioItem from './PortfolioItem';

const Img3 = ({data, i}) => {
  const reverse = data?.reverse;
  
  const img0 = data?.col0 ? data?.col0[0]?.featuredImage?.node : null;
  const text0 = data?.col0 ? data?.col0[0]?.acfPost?.subtitle : null;
  const type0 = data?.col0 ? data?.col0[0]?.acfPost?.type : null;
  const gallery0 = data?.col0 ? data?.col0[0]?.acfPost?.gallery : null;
  const video0 = data?.col0 ? data?.col0[0]?.acfPost?.video : null;

  const img1 = data?.col1 ? data?.col1[0]?.featuredImage?.node : null;
  const text1 = data?.col1 ? data?.col1[0]?.acfPost?.subtitle : null;
  const type1 = data?.col1 ? data?.col1[0]?.acfPost?.type : null;
  const gallery1 = data?.col1 ? data?.col1[0]?.acfPost?.gallery : null;
  const video1 = data?.col1 ? data?.col1[0]?.acfPost?.video : null;

  const img2 = data?.col2 ? data?.col2[0]?.featuredImage?.node : null;
  const text2 = data?.col2 ? data?.col2[0]?.acfPost?.subtitle : null;
  const type2 = data?.col2 ? data?.col2[0]?.acfPost?.type : null;
  const gallery2 = data?.col2 ? data?.col2[0]?.acfPost?.gallery : null;
  const video2 = data?.col2 ? data?.col2[0]?.acfPost?.video : null;


  return (
    <section data-scroll-section>
      <div className="container-fluid">
        <div className={reverse ? 'row flex-row-reverse' : 'row'}>
          <div className="col-lg-6">
            <PortfolioItem i={i + '-4'} img={img0} text={text0} type={type0} gallery={gallery0} video={video0} />
          </div>

          <div className="col-lg-3">
            <PortfolioItem i={i + '-5'} img={img1} text={text1} type={type1} gallery={gallery1} video={video1} />
          </div>

          <div className="col-lg-3">
            <PortfolioItem i={i + '-6'} img={img2} text={text2} type={type2} gallery={gallery2} video={video2} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Img3;
